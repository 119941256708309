import React from 'react'
import Layout from '../../components/Layout';
import { Link, graphql } from 'gatsby';
import Head from '../../components/Head';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Products({ data }) {

    const automationProds = data.automation.nodes
    const equipmentsProds = data.equipments.nodes
    const electricalProds = data.electrical.nodes

    return (
        <Layout>
            <Head title="Products" />
            <div className="container">
                <div className="row text-muted shadow-lg">
                    <div className="col-lg-3 dark-contrast-border lh-lg p-2">
                        <div className="sticky-top">
                            <h1 className="brand-color fw-bold text-end" style={{ fontSize: "3.5rem" }}>
                                products.
                            </h1>
                            <p className="fw-light text-end">
                                that matter.
                            </p>
                            <ul className="text-end page-nav">
                                <div>
                                    <ul>
                                        <li className="mb-2" key="automation-products">
                                            <Link to="#automation-products">Automation Products</Link>
                                        </li>
                                        <li className="mb-2" key="equipments-machines">
                                            <Link to="#equipments-machines">Equipments / Machines</Link>
                                        </li>
                                        <li className="mb-2" key="electrical-products">
                                            <Link to="#electrical-products">Electrical Products</Link>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-9 dark-contrast p-3 text-muted">
                        <small className="text-muted">The <span className="brand-color">products</span> we deal in are considered best in class.</small>
                        <div className="mt-5"></div>
                        <div>
                            <section id="automation-products">
                                <h4 className="brand-color border-bottom border-info lh-lg fw-light">Automation Products</h4>
                                {automationProds.map(product => (
                                    <div className="fw-light bg-transparent m-2">
                                        <button className="btn collapsible-btn text-start w-100 border-dark shadow-lg p-3 rounded-0 fw-light" type="button" data-bs-toggle="collapse" data-bs-target={`#${product.frontmatter.slug}-body`} aria-expanded="false" aria-controls="collapseExample">
                                            {product.frontmatter.title}
                                        </button>
                                        <div className="collapse" id={`${product.frontmatter.slug}-body`}>
                                            <div className="card card-body bg-dark lh-lg rounded-0 ">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: product.html }} className="align-self-center" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-center">
                                                        <GatsbyImage image={getImage(product.frontmatter.image.childImageSharp.gatsbyImageData)} alt={`${product.frontmatter.slug}`}/>
                                                    </div>
                                                </div>
                                                <div className="card-footer dark-contrast text-center border border-dark">
                                                    <small><Link to="/contact-us" className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>

                            <section id="equipments-machines">
                                <h4 className="brand-color border-bottom border-info lh-lg fw-light">Equipments / Machines</h4>
                                {equipmentsProds.map(product => (
                                    <div className="fw-light bg-transparent m-2">
                                        <button className="btn collapsible-btn text-start w-100 border-dark shadow-lg p-3 rounded-0 fw-light" type="button" data-bs-toggle="collapse" data-bs-target={`#${product.frontmatter.slug}-body`} aria-expanded="false" aria-controls="collapseExample">
                                            {product.frontmatter.title}
                                        </button>
                                        <div className="collapse" id={`${product.frontmatter.slug}-body`}>
                                            <div className="card card-body bg-dark lh-lg rounded-0 ">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: product.html }} className="align-self-center" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-center">
                                                        <GatsbyImage image={getImage(product.frontmatter.image.childImageSharp.gatsbyImageData)} alt={`${product.frontmatter.slug}`} />
                                                    </div>
                                                </div>
                                                <div className="card-footer dark-contrast text-center border border-dark">
                                                    <small><Link to="/contact-us" className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>

                            <section id="electrical-products">
                                <h4 className="brand-color border-bottom border-info lh-lg fw-light">Electrical Products</h4>
                                {electricalProds.map(product => (
                                    <div className="fw-light bg-transparent m-2">
                                        <button className="btn collapsible-btn text-start w-100 border-dark shadow-lg p-3 rounded-0 fw-light" type="button" data-bs-toggle="collapse" data-bs-target={`#${product.frontmatter.slug}-body`} aria-expanded="false" aria-controls="collapseExample">
                                            {product.frontmatter.title}
                                        </button>
                                        <div className="collapse" id={`${product.frontmatter.slug}-body`}>
                                            <div className="card card-body bg-dark lh-lg rounded-0 ">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: product.html }} className="align-self-center" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 text-center">
                                                        <GatsbyImage image={getImage(product.frontmatter.image.childImageSharp.gatsbyImageData)} alt={`${product.frontmatter.slug}`} />
                                                    </div>
                                                </div>
                                                <div className="card-footer dark-contrast text-center border border-dark">
                                                    <small><Link to="/contact-us" className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
query ProductsQuery {
    automation: allMarkdownRemark(
        filter: {
            frontmatter: {
                category: 
                {eq: "Automation Products"}
            }
        }
    ) 
    {
        nodes {
            id
            frontmatter {
                title
                slug
                image {
                    childImageSharp {
                        gatsbyImageData(
                            height:350
                            placeholder: TRACED_SVG
                            formats: [AUTO, WEBP, AVIF]
                        )
                        
                    }
                }
            }
            html
        }
    }
    equipments: allMarkdownRemark(
        filter: {
            frontmatter: {
                category: 
                {eq: "Equipments"}
            }
        }
    ) 
    {
        nodes {
            id
            frontmatter {
                title
                slug
                image {
                    childImageSharp {
                        gatsbyImageData(
                            height:350
                            placeholder: TRACED_SVG
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            html
        }
    }
    electrical: allMarkdownRemark(
        filter: {
            frontmatter: {
                category: 
                {eq: "Electrical Products"}
            }
        }
    ) 
    {
        nodes {
            id
            frontmatter {
                title
                slug
                image {
                    childImageSharp {
                        gatsbyImageData(
                            height:350
                            placeholder: TRACED_SVG
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            html
        }
    }
}
`